import React, { useState, useEffect, useContext, useRef } from 'react'
import '../css/details.scss'
import '../css/responsive/details_res.scss'
import DetailsModal from './modal/detailsModal'
import { Link, Navigate, Outlet, useLocation, useParams, useNavigate, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import DetailsModal2 from './modal/detailsModal2';
import { Tooltip } from 'react-tooltip';
import { TabView, TabPanel } from 'primereact/tabview';
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { CartContext } from '../context/cartContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colord } from 'colord';
import Details_Description from './details_Description';
import Details_Details from './details_Details';


export default function Details() {

    const swiperRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const urlToCopy = window.location.href;
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reset the copied state after 2 seconds
    };



    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const location = useLocation();



    const [selectedVariations, setSelectedVariations] = useState([]);

    const handleVariationClick = (variationType, variationId) => {

        const newVariations = selectedVariations.map(item =>
            item.type === variationType ? { type: variationType, id: variationId } : item
        );

        // If the variation type does not exist in the current array, add it
        if (!newVariations.some(item => item.type === variationType)) {
            newVariations.push({ type: variationType, id: variationId });
        }

        setSelectedVariations(newVariations);
        returnPrice(newVariations);
    };

    const handleColorClick = (variation) => {
        setSelectedVariation(variation);

        setSelectedColor(variation.value);
        handleVariationClick('Color', variation.id);
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(0);
        }
    };

    let { baseURL, token, updateFavLength, defaultImage, updateShopTitle, setExclusive, updateHeaderData, headerDate, setPag } = useContext(UserContext);
    const [details, setDetails] = useState([]);
    const [price, setPrice] = useState([]);
    const [related, setRelated] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const { refreshCart, getCart, sesId, loadIcon, setLoadIcon } = useContext(CartContext);
    const [brandModal, setBrandModal] = useState([]);

    useEffect(() => {

        if (details && details.brand && details.brand.id) {
            const brandId = details.brand.id;
            // 
            axios.get(`${baseURL}/brands/${brandId}/products`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then((response) => {
                    if (response.data && response.data.data) {
                        setBrandModal(response.data.data);

                    } else {
                        // console.error('Fetched data is not in the expected format', response.data);
                    }
                })
                .catch((error) => { });

        }

    }, [details, baseURL, token]);

    const { id } = useParams();
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        axios.get(`${baseURL}/products/${id}`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.data.data) {
                    setDetails(response.data.data);

                } else {
                    // console.error('Fetched data is not in the expected format', response.data);


                }
            })
            .catch((error) => { setIsEmpty(true); })
        window.scrollTo(0, 0);
        // 

    }, [id, baseURL, token])

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        setLoadIcon(true);
        if (details && details.variations) {

            const initialVariations = details.variations.map(variation => {
                if (variation.values && variation.values[0]) {
                    return { type: variation.name, id: variation.values[0].id };
                }
                return null;
            }).filter(v => v !== null);
            setSelectedVariations(initialVariations);
            returnPrice(initialVariations);
            // setIsInitialized(true);
        }
    }, [details, baseURL, token])

    const [isOutOfStock, setIsOutOfStock] = useState(false);
    const returnPrice = (variations) => {
        if (details && details.category && details.brand) {

            const product_id = details.id;
            const variation_values = variations.map(v => v.id);
       
            axios.get(`${baseURL}/products/details`, {
                params: {
                    product_id: product_id,
                    variation_values: variation_values
                },
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then((response) => {
                    if (response.data && response.data.data) {
                        const newPrice = response.data.data;
                        if (newPrice.image && newPrice.image.src) {
                            setSelectedImage(newPrice.image.src);

                        } else {

                        }
                        setPrice(response.data.data);

                        setIsOutOfStock(false);
                        setLoadIcon(false);
                        setNumber(1);
                    } else {
                        // console.error('Fetched data is not in the expected format', response.data);
                    }
                })
                .catch((error) => {
                    // setPrice(error.data.price);
                    setNumber(1);
                    setPrice(error.response.data.data);
                    // console.log(error.response.data.data.price, 'priceeee');

                    setSelectedImage(error.response.data.data.image_src);

                    setIsOutOfStock(true);
                    setLoadIcon(false);
                })
        }
    };

    useEffect(() => {
        if (details && details.category && details.brand) {
            const cat_id = details.category.id;
            const brand_id = details.brand.id;
            const prod_id = details.id
            axios.get(`${baseURL}/products/related_products`, {
                params: { category_id: cat_id, brand_id: brand_id, product_id: prod_id },
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then((response) => {
                    if (response.data && response.data.data) {
                        setRelated(response.data.data);
                    } else {
                        // console.error('Fetched data is not in the expected format', response.data);
                    }
                })
                .catch((error) => { });



        }
    }, [details, baseURL, token]);

    const updateFavorite = (id, variationValues) => {
        if (
            details &&
            details.isFavorite !== undefined
        ) {
            if (details.id === id) {
                // Check if variationValues is an empty array
                if (variationValues.length === 0) {
                    const updatedDetails = {
                        ...details,
                        isFavorite: !details.isFavorite
                    };


                    setDetails(updatedDetails);
                } else {
                    const hasMatchingVariation = details.variations.some(variation =>
                        variation.values.some(value => variationValues.includes(value.id))
                    );
                    if (hasMatchingVariation) {
                        const updatedDetails = {
                            ...details,
                            isFavorite: !details.isFavorite
                        };


                        setDetails(updatedDetails);
                    }
                }
            }
        }
    };




    const removeFromFav = (id, isFav) => {
        const variation_values = selectedVariations.map(v => v.id);
        if (isFav) {
            axios.delete(`${baseURL}/favorites`, {
                data: { product_id: id, variation_value_id: variation_values },
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(res => {

                    updateFavorite(id, variation_values);
                    updateFavLength(false);
                })
                .catch();
        } else {
            axios.post(`${baseURL}/favorites/add`, {
                product_id: id, variation_value_id: variation_values
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(response => {

                    updateFavorite(id, variation_values);
                    updateFavLength(true);
                })
                .catch();
        }
    };


    const getFirstWord = (value) => {
        const lowerValue = value.toLowerCase();
        // Check for specific keywords in the value
        if (lowerValue.includes('yellow gold')) {
            return '#dac379';
        }
        if (lowerValue.includes('white gold')) {
            return '#aeadad';
        }
        if (lowerValue.includes('rose gold')) {
            return '#c2a4a1';
        }
        if (lowerValue.includes('gold vermeil')) {
            return '#D4AF37';
        }
        // Default to the first word if no specific keyword is found
        const firstWord = value.split(' ')[0].toLowerCase();
        return firstWord;
    };
    useEffect(() => {
        setPag(location.state?.pagination);
        const handlePopState = () => {

            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);

        };

        //   Add popstate event listener
        window.addEventListener('popstate', handlePopState);

        return () => {
            //   Clean up the event listener on component unmount
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const navigate = useNavigate();



    const buyNow = () => {
        addToCart();
        navigate('/cart');
    }
    const [errorMessage, setErrorMessage] = useState('');
    const addToCart = () => {
        let firstVar2;
        setLoadIcon(true);
        if (!selectedVariation) {
            if (details && details.variations && details.variations.length > 0 && details.variations[0].values && details.variations[0].values.length > 0) {
                firstVar2 = details.variations[0].values[0].id;

            } else {
                firstVar2 = [];
            }
        } else {
            firstVar2 = selectedVariation.id;

        }
        const variation_values = selectedVariations.map(v => v.id);
        // const modifiedQuantities = (price.inventory_quantity !== -1 )
        //     ? (number > price.inventory_quantity ? price.inventory_quantity : number)
        //     : number;
        if (token) {
            if (details && details.id && firstVar2) {
                const firstId = details.id;

                axios.post(`${baseURL}/cart`, {
                    product_ids: firstId,
                    variation_values: firstVar2,
                    quantities: number,
                    variation_values_id: variation_values,

                }, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then((response) => {

                        // refreshCart();
                        getCart();

                    })
                    .catch((error) => {
                        setErrorMessage('Product Out Of Stock');
                        // Clear the error message after 3 seconds
                        setTimeout(() => {
                            setErrorMessage('');
                        }, 3000);
                    });
            } else {
                // console.error('Product details are incomplete or undefined');
            }
        }
        else {
            if (details && details.id && firstVar2) {
                const firstId = details.id;

                axios.post(`${baseURL}/cart`, {
                    product_ids: firstId,
                    variation_values: firstVar2,
                    quantities: number,
                    variation_values_id: variation_values,
                    session_id: sesId,
                })
                    .then((response) => {

                        // refreshCart();
                        getCart();

                    })
                    .catch((error) => {

                        setErrorMessage('Product Out Of Stock');
                        // Clear the error message after 3 seconds
                        setTimeout(() => {
                            setErrorMessage('');
                        }, 3000);
                    });
            } else {
                // console.error('Product details are incomplete or undefined');
            }
        }
    };


    const firstImage = details.images && details.images.length > 0 ? details.images[0] : null;


    let initialQuantity; // Default value if details is not truthy

    if (details && details.quantity) {
        initialQuantity = details.quantity;
        // 
    }

    const [number, setNumber] = useState(initialQuantity);
    useEffect(() => {
        setNumber(initialQuantity);
    }, [initialQuantity]);

    const handleMinusClick = () => {
        if (number > 1) {
            setNumber(number - 1);
        }
    };
    const handlePlusClick = () => {
        if (price.inventory_quantity === -1 || number < price.inventory_quantity) {
            setNumber(number + 1);
        }
    };
    const handleImageError = (e) => {
        e.target.src = defaultImage;
    };

    const handleNavigation = (productId) => {
        navigate(`/details/${productId}`);

        // window.location.reload(); 
    };
    // const [prevLocation, setPrevLocation] = useState(null);

    // useEffect(() => {
    //     if (location.pathname) {
    //       setPrevLocation(location.pathname);
    //     }
    //   }, [location.pathname]);

    const [selectedColor, setSelectedColor] = useState(null);
    const getArrowColor = (backgroundColor) => {
        return backgroundColor.toLowerCase() === 'black' ? '#fff' : '#000';
    };
    useEffect(() => {
        // Select the first color by default
        if (details.variations) {
            const colorVariation = details.variations.find(variation => variation.name === "Color");
            if (colorVariation && colorVariation.values.length > 0) {
                setSelectedColor(colorVariation.values[0].value);
            }
        }
    }, [details]);

    const [selectedImage, setSelectedImage] = useState(details.images && details.images.length > 0 ? details.images[0].image : `${process.env.PUBLIC_URL}/assets/default_img.svg`);

    useEffect(() => {
        // Update selectedImage when details.images changes
        if (details.images && details.images.length > 0) {
            setSelectedImage(details.images[0].image);
        }
    }, [details.images]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };
    //   ---------------------------------------------------------------------------------------------------------------------
    function getParentName() {
        if (location.state?.pathname.includes('/designers'))
            return 'designers | '
        else if (location.state?.pathname.includes('/collection'))
            return 'Shortlists | '
    }
    function navigateToParentName() {
        if (location.state?.pathname.includes('/designers'))
            navigate('/designers')
        else if (location.state?.pathname.includes('/collection'))
            navigate('/collections')
    }
    //  
    function navigateToBack() {
        if (location.state?.pathname.includes('/collection')) {
            let name = location.state.title
            navigate(location.state.pathname, { state: { name } })
        } else if (location.state?.pathname.includes('/category')) {
            let name = location.state.title
            let cate = { name }
            navigate(location.state.pathname, { state: { cate } })
        } else {
            navigate(location.state.pathname)
        }
    }
    const [currentTab, setCurrentTab] = useState('description'); // State to manage current tab

    //   ---------------------------------------------------------------------------------------------------------------------
    return (
        <>
            {/* {loadIcon && (
                <div className="loading-container">
                    <img src={`${process.env.PUBLIC_URL}/assests/loading-icon.gif`} alt="Loading..." className="checkout-video" />
                </div>
            )} */}
            <div className={`details `}>
                <div className="details-grey-line">
                    {location.state?.pathname
                        ? (
                            <>
                                {(
                                    location.state.pathname === `/products` || location.state.pathname.includes('/cate')) &&
                                    <div className="details-shop" onClick={() => { navigate('/products') }}>
                                        Shop |
                                    </div>
                                }
                                <div className="details-shop" onClick={navigateToParentName}> {getParentName()}

                                </div>
                                {location.state.title && <div className="details-shop" onClick={navigateToBack}>
                                    {location.state.title} | </div>}
                                <div className="details-details"> Details </div>
                            </>
                        )
                        : (
                            <>
                                <div className="details-shop" onClick={() => { navigate('/products') }}> Shop | </div>
                                <div className="details-details"> Details </div>
                            </>
                        )
                    }

                </div>
                <div className="details-wrapper-1">
                    {!isEmpty && (
                        <div className={`details-left-right ${token ? '' : 'details-center'}`}>
                            <div className="details-left">
                                <div className="detials-ring-img">
                                    {details.images && details.images.length > 0 && (

                                        <img src={details.images.length > 0 ? selectedImage : `${process.env.PUBLIC_URL}/assets/default_img.svg`} onError={handleImageError} />

                                    )}
                                    {details.images && details.images.length <= 0 && (

                                        <img src={`${process.env.PUBLIC_URL}/assets/default_img.svg`} onError={handleImageError} />
                                    )}
                                    {token && (
                                        <>
                                            {details && details.isFavorite !== undefined && (
                                                <img
                                                    src={details.isFavorite ? `${process.env.PUBLIC_URL}/assests/love3.svg` : `${process.env.PUBLIC_URL}/assests/love4.svg`}
                                                    alt=""
                                                    className='details-love'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeFromFav(details.id, details.isFavorite);
                                                    }}
                                                />
                                            )}
                                            <CopyToClipboard text={urlToCopy} onCopy={handleCopy}>
                                                <img src={`${process.env.PUBLIC_URL}/assests/link.svg`} alt="Copy link" className='details-love2' style={{ cursor: 'pointer' }} />

                                            </CopyToClipboard>
                                        </>
                                    )}
                                    {!token && (
                                        <CopyToClipboard text={urlToCopy} onCopy={handleCopy}>
                                            <img src={`${process.env.PUBLIC_URL}/assests/link.svg`} alt="Copy link" className='details-love2' style={{ cursor: 'pointer' }} />

                                        </CopyToClipboard>
                                    )}
                                </div>
                                <div className="details-ring-imgs">
                                    <div className="details-left-arrow">

                                        <img src=
                                            {`${process.env.PUBLIC_URL}/assests/home-right.png`}
                                            alt="" className='swiper-back2' />

                                    </div>
                                    <div className="home-swipers" id='home-swipers10'>
                                        <Swiper
                                            className=" "
                                            spaceBetween={10}
                                            slidesPerView={3}
                                            navigation={{
                                                nextEl: ".swiper-front2",
                                                prevEl: ".swiper-back2",
                                            }}
                                            modules={[Navigation, Autoplay]}

                                            // autoplay={{ delay: 2000 }}
                                            speed={2000}

                                        >
                                            {details.images && details.images.map((image, index) => (
                                                <SwiperSlide key={index}>

                                                    <div className="home-products-swiper" >

                                                        <div className="details-menu2" >
                                                            <div className="details-swiper-img-2">
                                                                <img src={image.image} alt="" onError={handleImageError}
                                                                    onClick={() => handleImageClick(image.image)} />
                                                            </div>

                                                        </div>


                                                    </div>

                                                </SwiperSlide>
                                            ))}

                                        </Swiper>
                                    </div>
                                    <div className="details-right-arrow">
                                        <img src=
                                            {`${process.env.PUBLIC_URL}/assests/home-right.png`}
                                            alt="" className='swiper-front2' />
                                    </div>
                                </div>
                            </div>



                            <div className="details-left-swiper">

                                <div className="home-swipers " id='details-res'>
                                    <Swiper ref={swiperRef}
                                        className=" pb-3 home-pag"
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        navigation={{
                                            nextEl: ".swiper-front2",
                                            prevEl: ".swiper-back2",
                                        }}
                                        modules={[Navigation, Autoplay, Pagination]}


                                        speed={2000}
                                        pagination={{
                                            clickable: true,

                                        }}
                                    >
                                        {details.images && details.images.map((image, index) => (
                                            <SwiperSlide key={index}>

                                                <div className="details-products-swiper " >

                                                    <div className="details-menu2-swiper" >
                                                        <div className="details-swiper-img-2-swiper">
                                                            {token && (
                                                                <>
                                                                    {details && details.isFavorite !== undefined && (
                                                                        <img
                                                                            src={details.isFavorite ? `${process.env.PUBLIC_URL}/assests/love3.svg` : `${process.env.PUBLIC_URL}/assests/love4.svg`}
                                                                            alt=""
                                                                            className='details-love'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                removeFromFav(details.id, details.isFavorite);
                                                                            }}
                                                                        />
                                                                    )}
                                                                    <CopyToClipboard text={urlToCopy} onCopy={handleCopy}>
                                                                        <img src={`${process.env.PUBLIC_URL}/assests/link.svg`} alt="Copy link" className='details-love2' style={{ cursor: 'pointer' }} />

                                                                    </CopyToClipboard>
                                                                </>
                                                            )}
                                                            <img
                                                                src={index === 0 ? selectedImage : image.image}
                                                                onError={handleImageError}
                                                                alt=""
                                                            />

                                                        </div>

                                                    </div>


                                                </div>

                                            </SwiperSlide>
                                        ))}

                                    </Swiper>
                                </div>

                            </div>





                            {details ? (

                                <div className="details-right" >

                                    {details && details.brand && details.brand.name && (
                                        <div className="details-rebel" onClick={handleOpenModal}>{details.brand.name}</div>
                                    )}
                                    <div className="details-golden">{details.name}</div>

                                    {/* <div className="details-color"> Price :</div> */}
                                    <div className="details-price">
                                        $
                                        {price && (
                                            price.price
                                        )}
                                    </div>
                                    {details.variations && details.variations.some(variation => variation.name === "Color") && (
                                        <>
                                            <div className="details-color">Color :</div>
                                            <div className="details-color-circles">
                                                <Tooltip id="my-tooltip" />
                                                {details.variations && details.variations.map((variation) => (
                                                    variation.name === "Color" && variation.values.map((value, index) => {
                                                        const firstWord = getFirstWord(value.value);
                                                        return (
                                                            <a data-tooltip-id="my-tooltip" data-tooltip-content={value.value} key={index} onClick={() => handleColorClick(value)}>
                                                                <div className="details-color-circle-1" style={{ backgroundColor: firstWord, position: 'relative' }}>
                                                                    {selectedColor === value.value && (
                                                                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: getArrowColor(value.value) }}>✔</span>
                                                                    )}
                                                                </div>
                                                            </a>
                                                        );
                                                    })
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    {details.variations && details.variations.map((variation, variationIndex) => (
                                        variation.name !== "Color" && (
                                            <div key={variationIndex}>
                                                <div className="details-color capitalize-first-letter">{variation.name} :</div>
                                                <div className="details-color-circles">
                                                    {Object.values(variation.values).map((value, index) => (
                                                        <div
                                                            key={index}
                                                            className={`details-size-number ${selectedVariations.some(selected => selected.type === variation.name && selected.id === value.id) ? 'selected' : ''}`}
                                                            onClick={() => handleVariationClick(variation.name, value.id)}
                                                        >
                                                            {value.value}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    ))}
                                    {token && (
                                        <>
                                            {errorMessage && <div className="recover-error-message">{errorMessage}</div>}
                                            <div className="details-quantity-add">
                                                <div className="details-minus" onClick={handleMinusClick}></div>
                                                <div className="details-number">
                                                    {number}
                                                </div>
                                                <div className="details-plus" onClick={handlePlusClick}></div>

                                                <button
                                                    className="details-add"
                                                    onClick={() => !isOutOfStock && addToCart()}
                                                    disabled={isOutOfStock || loadIcon}
                                                    style={{ cursor: isOutOfStock || loadIcon ? 'not-allowed' : 'pointer' }}
                                                >
                                                    {loadIcon || isOutOfStock ? (
                                                        // Show loading dots or OUT OF STOCK message
                                                        loadIcon ? (
                                                            <div className="dots-loading">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/dots.png`} alt="" />
                                                                <img src={`${process.env.PUBLIC_URL}/assets/dots.png`} alt="" />
                                                                <img src={`${process.env.PUBLIC_URL}/assets/dots.png`} alt="" />
                                                            </div>
                                                        ) : (
                                                            'OUT OF STOCK'
                                                        )
                                                    ) : (
                                                        // Show the "ADD TO CART" text when not out of stock and not loading
                                                        'ADD TO CART'
                                                    )}
                                                </button>


                                            </div>
                                            {!isOutOfStock && (
                                                <div className="details-buy-now" onClick={() => buyNow()}>BUY NOW</div>
                                            )}
                                        </>)}
                                    {!token && (
                                        <>
                                            {errorMessage && <div className="recover-error-message">{errorMessage}</div>}
                                            <div className="details-quantity-add">
                                                <div className="details-minus" onClick={handleMinusClick}></div>
                                                <div className="details-number">
                                                    {number}
                                                </div>
                                                <div className="details-plus" onClick={handlePlusClick}></div>

                                                <buttton className="details-add" id='details-add-token' onClick={() => !isOutOfStock && addToCart()} disabled={isOutOfStock} style={{ cursor: isOutOfStock ? 'not-allowed' : 'pointer' }}> {loadIcon ? (
                                                    <div className="dots-loading">
                                                        <img src={`${process.env.PUBLIC_URL}/assests/dots.png`} alt="" />
                                                        <img src={`${process.env.PUBLIC_URL}/assests/dots.png`} alt="" />
                                                        <img src={`${process.env.PUBLIC_URL}/assests/dots.png`} alt="" />
                                                    </div>
                                                ) : (
                                                    isOutOfStock ? 'OUT OF STOCK' : 'ADD TO CART'
                                                )} </buttton>


                                            </div>
                                        </>
                                    )}
                                    {/* <div className="details-golden" dangerouslySetInnerHTML={{ __html: details.description }} /> */}
                                </div>

                            ) : (
                                <p>Loading product details...</p>
                            )}
                        </div>
                    )}
                    {isEmpty && (
                        <>
                            <p className='details-no'>No Product Found</p>
                            <Link to='/products' className="details-add" style={{ marginLeft: 'auto', marginRight: 'auto', textDecoration: 'none' }}>Shop Now</Link>
                        </>
                    )}
                    {copied && <span className='details-link'>Link copied!</span>}
                </div>
                <div className="details-wrapper-2">
                    {details && details.id && (
                        <>
                            {/* <div className="details-description-details">
                <Link to={`description`} className={location.pathname === `/details/${id}/description` ? 'active' : 'details-description'}>
                    Description
                </Link>
                <Link to={`details`} className={location.pathname === `/details/${id}/details` ? 'active' : 'details-details'}>
                    Details
                </Link>
            </div>


                <Outlet context={details} /> */}

                            <div className="details-description-details">
                                <div
                                    className={currentTab === 'description' ? 'active' : 'details-description'}
                                    onClick={() => setCurrentTab('description')}
                                >
                                    Description
                                </div>
                                <div
                                    className={currentTab === 'details' ? 'active' : 'details-details'}
                                    onClick={() => setCurrentTab('details')}
                                >
                                    Details
                                </div>
                            </div>
                            <div>
                                {currentTab === 'description' && <Details_Description details={details} />}
                                {currentTab === 'details' && <Details_Details details={details} />}
                            </div>







                            <div className="details-new-arrival-back-front-imgs">


                                <div className="home-new-arrival" id='details-new-arrival'>
                                    You May Also Like
                                </div>
                                {/* {related.length > 0 && ( */}

                                <div className="home-back-front-imgs" id='details-back-front-imgs'>
                                    <div className="home-back-img swiper-backk" >
                                        <img src=
                                            {`${process.env.PUBLIC_URL}/assests/home-back.png`}
                                            alt="" className='swiper-back' />
                                        <img src={`${process.env.PUBLIC_URL}/assests/home-front.png`} alt="" className="swiper-back-hover" />
                                    </div>
                                    <div className="home-front-img swiper-frontt" >
                                        <img src=
                                            {`${process.env.PUBLIC_URL}/assests/home-back.png`}
                                            alt="" className='swiper-front' />
                                        <img src={`${process.env.PUBLIC_URL}/assests/home-front.png`} alt="" className="swiper-front-hover" />

                                    </div>
                                </div>
                                {/* )} */}
                            </div>
                            <div className="home-swipers">
                                <Swiper
                                    className="mt-4 pb-5"
                                    spaceBetween={10}
                                    slidesPerView={2}
                                    navigation={{
                                        nextEl: ".swiper-frontt",
                                        prevEl: ".swiper-backk",
                                    }}
                                    modules={[Navigation, Autoplay, Pagination]}

                                    // autoplay={{ delay: 2000 }}
                                    speed={2000}
                                    pagination={{
                                        clickable: true,
                                        bulletClass: "swiper-pagination-bullet",
                                        bulletActiveClass: "swiper-pagination-bullet-active",
                                        renderBullet: (index, className) => {
                                            if (index < 4) {
                                                return `<span class="${className}"></span>`;
                                            }
                                            return '';
                                        },
                                    }}


                                    breakpoints={{
                                        // when window width is >= 640px
                                        900: {
                                            slidesPerView: 3,
                                        }

                                    }}
                                >

                                    {related && related.map((product) => (

                                        <SwiperSlide key={product.id}>
                                            <div className="home-products-swiper2" onClick={() => handleNavigation(product.id)}>
                                                <div className="details-menu">
                                                    {product && product.images[0] && (
                                                        <div className="details-swiper-img-1" id='home-swiper-img-1'>
                                                            <img src={product.images[0].image} alt={product.name} onError={handleImageError} />
                                                        </div>
                                                    )}
                                                    <div className="home-golden">{product.name}</div>
                                                    <div className="home-price">${product.price}</div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                    )}

                                </Swiper>
                            </div>
                        </>
                    )}
                </div>

                <DetailsModal show={showModal} handleClose={handleCloseModal} brandModal={brandModal} />
                {/* <DetailsModal2 show={showModal2} handleClose={handleCloseModal2} /> */}

            </div>
        </>

    )
}
